import React from "react";
import Header from "../components/Layout/Header";
import Footer from "../components/Layout/Footer";
import ConditionsGeneral from "../components/Route/ConditionsGeneral/ConditionsGeneral";

const ConditionsGeneralPage = () => {
  return (
    <>
      <Header />
      <ConditionsGeneral />
      <Footer />
    </>
  );
};

export default ConditionsGeneralPage;
