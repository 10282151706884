import React, { useState } from "react";
import {
  AiFillHeart,
  AiOutlineEye,
  AiOutlineHeart,
  AiOutlineShoppingCart,
} from "react-icons/ai";
import { Link } from "react-router-dom";
import { backend_url } from "../../../server";
import styles from "../../../styles/styles";
import { useDispatch, useSelector } from "react-redux";
import ProductDetailsCard from "../ProductDetailsCard/ProductDetailsCard";
import {
  addToWishlist,
  removeFromWishlist,
} from "../../../redux/actions/wishlist";
import { useEffect } from "react";
import { addTocart } from "../../../redux/actions/cart";
import { toast } from "react-toastify";
import {
  GetProductDescription,
  GetProductName,
} from "../../../utils/ProductInfo";
import { useTranslation } from "react-i18next";

const ProductCardS = ({ data, isEvent }) => {
  const { wishlist } = useSelector((state) => state.wishlist);
  const { cart } = useSelector((state) => state.cart);
  const [click, setClick] = useState(false);
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();
  const { i18n } = useTranslation();
  const ProductName = GetProductName(data, i18n);
  const ProductDescription = GetProductDescription(data, i18n);

  useEffect(() => {
    if (wishlist && wishlist.find((i) => i._id === data._id)) {
      setClick(true);
    } else {
      setClick(false);
    }
  }, [wishlist, data._id]);

  const removeFromWishlistHandler = (data) => {
    setClick(!click);
    dispatch(removeFromWishlist(data));
  };

  const addToWishlistHandler = (data) => {
    setClick(!click);
    dispatch(addToWishlist(data));
  };

  const addToCartHandler = (id) => {
    const isItemExists = cart && cart.find((i) => i._id === id);
    if (isItemExists) {
      toast.error("Item already in cart!");
    } else {
      if (data.stock < 1) {
        toast.error("Product stock limited!");
      } else {
        const cartData = { ...data, qty: 1 };
        dispatch(addTocart(cartData));
        toast.success("Item added to cart successfully!");
      }
    }
  };

  return (
    <>
      <div className="w-[300px] h-[420px] max-[300px]:ml-[0.5rem] bg-white rounded-2xl shadow-sm p-3 m-3 relative cursor-pointer">
        <div className="flex justify-end"></div>
        <Link to={`${`/product/${data._id}`}`} reloadDocument={true}>
          <img
            src={`${backend_url}${data.images && data.images[0]}`}
            alt=""
            loading="lazy"
            className="w-[90%] rounded-xl h-[170px] object-contain"
          />
        </Link>
        <div className="h-[130px] ">
          <Link to={`${`/product/${data._id}`}`} reloadDocument={true}>
            <h5 className="font-bold text-[22px]  mt-5">
              {ProductName.length > 18
                ? ProductName.slice(0, 18) + ".."
                : ProductName}
            </h5>
          </Link>
          <Link to={`${`/product/${data._id}`}`} reloadDocument={true}>
            <h4 className=" font-[500]">
              {ProductDescription.length > 70
                ? ProductDescription.slice(0, 70) + "..."
                : ProductDescription}
            </h4>
          </Link>
          <Link to={`${`/product/${data._id}`}`}>
            <div className="flex pt-3">
              <h4
                className={`${styles.productDiscountPrice} text-[15px] text-[#F1634C]`}
              >
                {data.originalPrice}MAD
              </h4>{" "}
              {data.discountPrice ? (
                <h3 className={`${styles.price} text-[#4c4c4caa]`}>
                  {data.discountPrice + "MAD"}
                </h3>
              ) : (
                ""
              )}
            </div>
          </Link>
        </div>
        <div>
          {click ? (
            <AiFillHeart
              size={22}
              className="cursor-pointer absolute right-2 top-5"
              onClick={() => removeFromWishlistHandler(data)}
              color={click ? "red" : "#333"}
              title="Remove from wishlist"
            />
          ) : (
            <AiOutlineHeart
              size={22}
              className="cursor-pointer absolute right-2 top-5"
              onClick={() => addToWishlistHandler(data)}
              color={click ? "red" : "#333"}
              title="Add to wishlist"
            />
          )}
          <AiOutlineEye
            size={22}
            className="cursor-pointer absolute right-2 top-14"
            onClick={() => setOpen(!open)}
            color="#333"
            title="Quick view"
          />
          <AiOutlineShoppingCart
            size={25}
            className="cursor-pointer absolute right-2 top-24"
            onClick={() => addToCartHandler(data._id)}
            color="#444"
            title="Add to cart"
          />
          {open ? <ProductDetailsCard setOpen={setOpen} data={data} /> : null}
        </div>
        <div className="flex justify-center">
          <Link
            to={`${`/product/${data._id}`}`}
            reloadDocument={true}
            className="bg-[#F1634C] w-[43%] mt-3 p-3 justify-center rounded-md  text-[#FFF] flex items-center"
          >
            See more
          </Link>
        </div>
        <div className="flex">{/* <Ratings rating={data?.ratings} /> */}</div>
        {/* <div className="py-2 flex items-center justify-between">
            <div className="flex">
              <h5 className={`${styles.productDiscountPrice}`}>
                {data.originalPrice === 0
                  ? data.originalPrice
                  : data.discountPrice}
                $
              </h5>
              <h4 className={`${styles.price}`}>
                {data.originalPrice ? data.originalPrice + " $" : null}
              </h4>
            </div>
            <span className="font-[400] text-[17px] text-[#68d284]">
              {data?.sold_out} sold
            </span>
          </div> */}

        {/* side options */}
        {/* <div>
          {click ? (
            <AiFillHeart
              size={22}
              className="cursor-pointer absolute right-2 top-5"
              onClick={() => removeFromWishlistHandler(data)}
              color={click ? "red" : "#333"}
              title="Remove from wishlist"
            />
          ) : (
            <AiOutlineHeart
              size={22}
              className="cursor-pointer absolute right-2 top-5"
              onClick={() => addToWishlistHandler(data)}
              color={click ? "red" : "#333"}
              title="Add to wishlist"
            />
          )}
          <AiOutlineEye
            size={22}
            className="cursor-pointer absolute right-2 top-14"
            onClick={() => setOpen(!open)}
            color="#333"
            title="Quick view"
          />
          <AiOutlineShoppingCart
            size={25}
            className="cursor-pointer absolute right-2 top-24"
            onClick={() => addToCartHandler(data._id)}
            color="#444"
            title="Add to cart"
          />
          {open ? <ProductDetailsCard setOpen={setOpen} data={data} /> : null}
        </div> */}
      </div>
    </>
  );
};

export default ProductCardS;
