const GetProductName = (product, i18n) => {
  let ProductName =
    product.name[i18n.language] || product.name.ar || product.name;
  return ProductName;
};

const GetProductDescription = (product, i18n) => {
  let ProductDescription =
    product.description[i18n.language] ||
    product.description.ar ||
    product.description;
  return ProductDescription;
};

const GetProductPrice = (product) => {
  return product.discountPrice ||
    product.discountPrice === 0 ||
    product.discountPrice === "" ||
    product.discountPrice === null
    ? product.originalPrice
    : product.discountPrice;
};

export { GetProductName, GetProductDescription, GetProductPrice };
