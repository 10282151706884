import React from "react";
import brand1 from "../../Assests/image/1.png";
import brand2 from "../../Assests/image/2.png";
import brand3 from "../../Assests/image/3.png";
import brand4 from "../../Assests/image/4.png";
import brand5 from "../../Assests/image/5.png";
import brand6 from "../../Assests/image/ifassen.png";
import brand7 from "../../Assests/image/bohe-beldi.jpg";

import { useTranslation } from "react-i18next";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

const Sponsored = () => {
  const { t } = useTranslation();

  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 5,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 3,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  return (
    <div className="">
      <div className=" flex justify-center ">
        <div className="w-[85%] font-bold text-[30px] ">
          {" "}
          {t("Choicebybrand")}
          <br /> <div className="bg-[#F1634C] h-2  w-[6%]"></div>
        </div>
      </div>
      <div className="mt-9 flex justify-center ">
        {/* <div className="flex justify-center flex-wrap"> */}
        <Carousel responsive={responsive} className=" w-[85%] ">
          <div className="w-[250px] h-[250px] m-3 bg-white flex justify-center rounded-2xl shadow-sm p-3 relative cursor-pointer">
            <img
              src={brand1}
              alt=""
              style={{ width: "150px", objectFit: "contain" }}
            />
          </div>
          <div className="w-[250px] h-[250px] m-3 bg-white flex justify-center rounded-2xl shadow-sm p-3 relative cursor-pointer">
            <img
              src={brand2}
              alt=""
              style={{ width: "150px", objectFit: "contain" }}
            />
          </div>
          <div className="w-[250px] h-[250px] m-3 bg-white flex justify-center rounded-2xl shadow-sm p-3 relative cursor-pointer">
            <img
              src={brand3}
              alt=""
              style={{ width: "150px", objectFit: "contain" }}
            />
          </div>
          <div className="w-[250px] h-[250px] m-3 bg-white flex justify-center rounded-2xl shadow-sm p-3 relative cursor-pointer">
            <img
              src={brand4}
              alt=""
              style={{ width: "150px", objectFit: "contain" }}
            />
          </div>
          <div className="w-[250px] h-[250px] m-3 bg-white flex justify-center rounded-2xl shadow-sm p-3 relative cursor-pointer">
            <img
              src={brand5}
              alt=" brand5 "
              style={{ width: "150px", objectFit: "contain" }}
            />
          </div>
          <div className="w-[250px] h-[250px] m-3 bg-white flex justify-center rounded-2xl shadow-sm p-3 relative cursor-pointer">
            <img
              src={brand6}
              alt=" brand6 "
              style={{ width: "150px", objectFit: "contain" }}
            />
          </div>
          <div className="w-[250px] h-[250px] m-3 bg-white flex justify-center rounded-2xl shadow-sm p-3 relative cursor-pointer">
            <img
              src={brand7}
              alt="brand7"
              style={{ width: "150px", objectFit: "contain" }}
            />
          </div>
        </Carousel>

        {/* </div> */}
      </div>
    </div>
  );
};

export default Sponsored;
