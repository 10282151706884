import React, { useState } from "react";
import { RxCross1 } from "react-icons/rx";
import { BsCartPlus } from "react-icons/bs";
import styles from "../../styles/styles";
import { AiOutlineHeart } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import { removeFromWishlist } from "../../redux/actions/wishlist";
import { backend_url } from "../../server";
import { addTocart } from "../../redux/actions/cart";
import { useTranslation } from "react-i18next";
import { GetProductName, GetProductPrice } from "../../utils/ProductInfo";

const Wishlist = ({ setOpenWishlist }) => {
  const { t } = useTranslation();

  const { wishlist } = useSelector((state) => state.wishlist);
  const dispatch = useDispatch();

  const removeFromWishlistHandler = (data) => {
    dispatch(removeFromWishlist(data));
  };

  const addToCartHandler = (data) => {
    const newData = { ...data, qty: 1 };
    dispatch(addTocart(newData));
    setOpenWishlist(false);
  };

  return (
    <>
      <div className="fixed animate-fade-left animate-once animate-duration-500 top-0 right-0 h-full w-[80%] overflow-y-scroll  z-[770] 800px:w-[25%] bg-white flex flex-col justify-between shadow-sm">
        {wishlist && wishlist.length === 0 ? (
          <div className="w-full h-screen flex items-center justify-center">
            <div className="flex w-full justify-end pt-5 pr-5 fixed top-3 right-3">
              <RxCross1
                size={25}
                className="cursor-pointer"
                onClick={() => setOpenWishlist(false)}
              />
            </div>
            <h5>{t("WishlistItemsisempty")}</h5>
          </div>
        ) : (
          <>
            <div>
              <div className="flex w-full justify-end pt-5 pr-5">
                <RxCross1
                  size={25}
                  className="cursor-pointer"
                  onClick={() => setOpenWishlist(false)}
                />
              </div>
              {/* Item length */}
              <div className={`${styles.noramlFlex} p-4`}>
                <AiOutlineHeart size={25} />
                <h5 className="pl-2 text-[20px] font-[500]">
                  {wishlist && wishlist.length} {t("items")}
                </h5>
              </div>

              {/* cart Single Items */}
              <br />
              <div className="w-full border-t">
                {wishlist &&
                  wishlist.map((i, index) => (
                    <CartSingle
                      key={index}
                      data={i}
                      removeFromWishlistHandler={removeFromWishlistHandler}
                      addToCartHandler={addToCartHandler}
                    />
                  ))}
              </div>
            </div>
          </>
        )}
      </div>
      <div
        onClick={() => setOpenWishlist(false)}
        className=" animate-fade-left animate-once animate-duration-600  z-[760]   fixed top-0 left-0 w-full bg-[#0000004b] h-screen"
      ></div>
    </>
  );
};

const CartSingle = ({ data, removeFromWishlistHandler, addToCartHandler }) => {
  const [value, setValue] = useState(1);
  const totalPrice = GetProductPrice(data) * value;

  const { i18n } = useTranslation();

  const ProductName = data && GetProductName(data, i18n);

  return (
    <div className="border-b flex p-4">
      <div className="w-[40%] flex justify-center  h-[40%]  ">
        <img
          src={`${backend_url}${data?.images[0]}`}
          alt=""
          className="w-[130px] h-min ml-2 mr-2 rounded-[5px]"
        />
      </div>
      <div className="w-[40%]  font-bold text-[20px] mt-10 text-black">
        {ProductName} <br />
        <h4 className="font-[600] text-[17px]  pt-[3px] text-[#F1634C] font-Roboto">
          MAD{totalPrice}
        </h4>{" "}
      </div>{" "}
      <div className="w-[15%] flex justify-center items-center  ">
        {" "}
        <BsCartPlus
          size={30}
          className="cursor-pointer"
          tile="Add to cart"
          onClick={() => addToCartHandler(data)}
        />
      </div>
      <div className="w-[5%] ">
        {" "}
        <RxCross1
          className="cursor-pointer 800px:mb-['unset'] 800px:ml-['unset'] mb-2 ml-2"
          onClick={() => removeFromWishlistHandler(data)}
        />
      </div>
    </div>
  );
};

export default Wishlist;
