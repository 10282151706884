import { React, useState } from "react";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import styles from "../../styles/styles";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { server } from "../../server";
import { toast } from "react-toastify";
import { RxAvatar } from "react-icons/rx";
import { TypeShopData } from "../../static/data.js";
import { useTranslation } from "react-i18next";
import { PhoneInput } from "react-international-phone";
import "react-international-phone/style.css";
import LoadingButton from "../Layout/LoadingButton.jsx";

const ShopCreate = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState();
  const [address, setAddress] = useState("");
  const [zipCode, setZipCode] = useState("");
  const [sector, setSector] = useState("");
  const [typeShop, setTypeShop] = useState("");
  const [avatar, setAvatar] = useState("");
  const [password, setPassword] = useState("");
  const [visible, setVisible] = useState(false);
  const { t } = useTranslation();
  const [isSending, setIsSending] = useState(false);

  const handleSubmit = async (e) => {
    setIsSending(true);
    e.preventDefault();
    const config = {
      headers: { "Content-Type": "multipart/form-data" },
      withCredentials: true,
    };

    const newForm = new FormData();

    newForm.append("file", avatar);
    newForm.append("name", name);
    newForm.append("email", email);
    newForm.append("password", password);
    newForm.append("zipCode", zipCode);
    newForm.append("sector", sector);
    newForm.append("typeShop", typeShop);
    newForm.append("address", address);
    newForm.append("phoneNumber", phoneNumber);

    if (phoneNumber.length < 5) {
      setIsSending(false);
      toast.error(" Please provide a phone number ");
      return false;
    } else if (password.length < 6) {
      console.log(password.length);
      setIsSending(false);
      toast.error("Password must be 6 characters long");
      return false;
    }

    axios
      .post(`${server}/shop/create-shop`, newForm, config)
      .then((res) => {
        setIsSending(false);
        toast.success("Seller Login Success!");
        navigate("/dashboard");
        window.location.reload(true);
      })
      .catch((error) => {
        setIsSending(false);
        if (error.response.status === 500) {
          toast.error("Internal server error  please try leater  ");
        } else if (error.response.status !== 500) {
          toast.error(error.response.data.message);
        } else {
          toast.error(
            error.response?.data?.message ||
              "Something went wrong please try leater "
          );
        }
      });
  };

  const handleFileInputChange = (e) => {
    const file = e.target.files[0];
    setAvatar(file);
  };

  return (
    <div className=" flex flex-col justify-center py-12 bg-white  ">
      <div className="sm:mx-auto sm:w-full sm:max-w-md bg-white rounded-md sm:border-solid  border border-gray-200 border-none  py-8 px-8  ">
        <div className=" w-full flex justify-center items-center mb-4 ">
          <h2 className=" text-center text-[20px] capitalize font-[700] text-gray-900">
            {t("Registerasaseller")}
          </h2>
        </div>
        <form className="space-y-6" onSubmit={handleSubmit}>
          <div>
            <label
              htmlFor="email"
              className="block text-sm font-medium text-gray-700"
            >
              {t("ShopName")}
            </label>
            <div className="mt-1">
              <input
                type="name"
                name="name"
                required
                value={name}
                onChange={(e) => setName(e.target.value)}
                className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
              />
            </div>
          </div>

          <div>
            <label
              htmlFor="email"
              className="block text-sm font-medium text-gray-700"
            >
              {t("PhoneNumber")}
            </label>
            <div className="mt-1">
              <PhoneInput
                defaultCountry="ma"
                value={phoneNumber}
                onChange={(phone) => setPhoneNumber(phone)}
                className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
              />
            </div>
          </div>

          <div>
            <label
              htmlFor="email"
              className="block text-sm font-medium text-gray-700"
            >
              {t("Email")}
            </label>
            <div className="mt-1">
              <input
                type="email"
                name="email"
                autoComplete="email"
                required
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
              />
            </div>
          </div>

          <div>
            <label
              htmlFor="sector"
              className="block text-sm font-medium text-gray-700"
            >
              {t("sector")}
            </label>
            <div className="mt-1">
              <input
                type="name"
                name="sector"
                required
                value={sector}
                onChange={(e) => setSector(e.target.value)}
                className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
              />
            </div>
          </div>

          <div>
            <label
              htmlFor="sector"
              className="block text-sm font-medium text-gray-700"
            >
              {t("typeShop")}
            </label>
            <div className="mt-1">
              <select
                value={typeShop}
                required
                onChange={(e) => setTypeShop(e.target.value)}
                className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
              >
                <option value="Choose a category">
                  {t("ChooseatypeShop")}
                </option>
                {TypeShopData &&
                  TypeShopData.map((i) => (
                    <option value={i.title} key={i.id}>
                      {t(i.title)}
                    </option>
                  ))}
              </select>
            </div>
          </div>

          <div>
            <label
              htmlFor="email"
              className="block text-sm font-medium text-gray-700"
            >
              {t("Address")}
            </label>
            <div className="mt-1">
              <input
                type="address"
                name="address"
                required
                value={address}
                onChange={(e) => setAddress(e.target.value)}
                className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
              />
            </div>
          </div>

          <div>
            <label
              htmlFor="email"
              className="block text-sm font-medium text-gray-700"
            >
              {t("ZipCode")}
            </label>
            <div className="mt-1">
              <input
                type="number"
                name="zipcode"
                value={zipCode}
                onChange={(e) => setZipCode(e.target.value)}
                className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
              />
            </div>
          </div>

          <div>
            <label
              htmlFor="password"
              className="block text-sm font-medium text-gray-700"
            >
              Password
            </label>
            <div className="mt-1 relative">
              <input
                type={visible ? "text" : "password"}
                name="password"
                autoComplete="current-password"
                required
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
              />
              {visible ? (
                <AiOutlineEye
                  className="absolute right-2 top-2 cursor-pointer"
                  size={25}
                  onClick={() => setVisible(false)}
                />
              ) : (
                <AiOutlineEyeInvisible
                  className="absolute right-2 top-2 cursor-pointer"
                  size={25}
                  onClick={() => setVisible(true)}
                />
              )}
            </div>
          </div>

          <div>
            <label
              htmlFor="avatar"
              className="block text-sm font-medium text-gray-700"
            ></label>
            <div className="mt-2 flex items-center">
              <span className="inline-block h-8 w-8 rounded-full overflow-hidden">
                {avatar ? (
                  <img
                    src={URL.createObjectURL(avatar)}
                    alt="avatar"
                    loading="lazy"
                    className="h-full w-full object-cover rounded-full"
                  />
                ) : (
                  <RxAvatar className="h-8 w-8" />
                )}
              </span>
              <label
                htmlFor="file-input"
                className="ml-5 flex items-center justify-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50"
              >
                <span>{t("Uploadafile")}</span>
                <input
                  type="file"
                  name="avatar"
                  id="file-input"
                  onChange={handleFileInputChange}
                  className="sr-only"
                />
              </label>
            </div>
          </div>

          <div>
            <LoadingButton isSending={isSending} textButton={"Create Shop"} />
          </div>
          <div className={`${styles.noramlFlex} w-full`}>
            <h4>{t("Alreadyhaveanaccount")}</h4>
            <Link to="/shop-login" className="text-blue-600 pl-2">
              {t("Signin")}
            </Link>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ShopCreate;
