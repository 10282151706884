import React, { useState } from "react";
import { Link } from "react-router-dom";
import { FaFacebookSquare, FaLinkedin, FaInstagram } from "react-icons/fa";
import { footerSupportLinks, categoriesData } from "../../static/data";
import logo from "../../Assests/Logo.png";
import { useTranslation } from "react-i18next";
import CMILogo from "../../Assests/image/CMI-PY.png";
import axios from "axios";
import { server } from "../../server";
import { toast } from "react-toastify";

const Footer = () => {
  const { t } = useTranslation();

  const [email, setEmail] = useState("");
  const [isSending, setisSending] = useState(false);

  const HandelSubmit = (e) => {
    setisSending(true);
    e.preventDefault();

    axios
      .post(`${server}/news/subscribe`, { email })
      .then((res) => {
        setisSending(false);
        if (res.request.status === 201) {
          setEmail("");
          toast.success("Subscribed successfully");
          setEmail("");
        }
      })
      .catch((error) => {
        setisSending(false);
        toast.error(error.response.data.message);
      });
  };

  return (
    <footer className=" bg-[#191C1F] w-full mt-4 ">
      <div className=" main-container ">
        <div className=" flex  flex-col 1100px:flex-row justify-evenly  h-fit   ">
          <div className=" flex flex-col  items-center 1100px:items-start py-5 1100px:py-14">
            <div className=" w-20 h-20  mb-8">
              <Link to="/">
                <img
                  src={logo}
                  alt="commerces connect logo"
                  className="w-full"
                  loading="lazy"
                />
              </Link>
            </div>
            <div className=" flex flex-col items-center 1100px:items-start ">
              <h4 className=" text-[#77878F] text-14 font-normal leading-5 ">
                Customer Supports:
              </h4>
              <a
                className=" mt-3 text-white font-medium text-[16px] leading-6 "
                href="tel:+212674042632"
              >
                +212 674-042632
              </a>
              <a
                className="  mt-3 text-white font-medium text-[16px] leading-6 "
                href="mailto:contact@commercesconnect.com"
              >
                contact@commercesconnect.com
              </a>
              <div className=" flex mt-3 justify-center items-center  ">
                <a
                  href="https://facebook.com/profile.php?id=100095508759370"
                  className="ml-4 cursor-pointer  "
                >
                  <FaFacebookSquare className=" text-white text-lg  " />
                </a>
                <a
                  href="hhttps://www.instagram.com/commercesconnect?igshid=NGVhN2U2NjQ0Yg%3D%3D"
                  className="ml-4 cursor-pointer  "
                >
                  <FaInstagram className=" text-white text-lg  " />
                </a>
                <a
                  href="https://www.linkedin.com/company/commerces-connect"
                  className="ml-4 cursor-pointer  "
                >
                  <FaLinkedin className=" text-white text-lg  " />
                </a>
                <p className=" cursor-pointer mt-[4px]  ">
                  <img
                    src={CMILogo}
                    alt="CMI-PY"
                    loading="lazy"
                    className=" w-8 ml-[15px] "
                  />
                </p>
              </div>
            </div>
          </div>
          <div className=" flex flex-col  items-center 1100px:items-start  py-3 1100px:py-14 ">
            <h4 className=" uppercase text-white mb-3 text-[16px] font-medium leading-6 ">
              Top Category
            </h4>
            <ul className="flex flex-col 1100px:items-start items-center">
              {categoriesData.map((category) => (
                <li
                  key={category.id}
                  className=" text-14 mb-2 hover:text-teal-400 cursor-pointer font-medium leading-5 text-[#929FA5] capitalize  "
                >
                  <Link to={`/category/${category.name}`}>
                    {t(category.name)}
                  </Link>
                </li>
              ))}
            </ul>
          </div>
          <div className=" flex flex-col  items-center 1100px:items-start  py-3 1100px:py-14">
            <h4 className=" uppercase text-white mb-3 text-[16px] font-medium leading-6 ">
              Quick links
            </h4>
            <ul className=" flex flex-col 1100px:items-start items-center ">
              {footerSupportLinks.map((link) => (
                <li
                  key={link.name}
                  className=" text-14 mb-2 hover:text-teal-400 1100px:w-[9rem] cursor-pointer font-medium leading-5 text-[#929FA5] capitalize  "
                >
                  <Link to={link.link}> {t(link.name)}</Link>
                </li>
              ))}
            </ul>
          </div>
          <div className=" flex flex-col  items-center 1100px:items-start  pt-3 pb-5 1100px:py-14">
            <h4 className=" text-[#F2F4F5] text-base font-semibold leading-10  ">
              Subscribe to our newsletter
            </h4>
            <p className=" text-[#F2F4F5] text-14 font-normal leading-6 w-full text-center   ">
              Get the latest updates on new products and upcoming sales
            </p>
            <form className=" mt-8 " onSubmit={HandelSubmit}>
              <div className=" w-80 md:w-96 h-11 md:py-[6px] px-2 rounded-[3px] bg-[#fff] flex items-center justify-between  ">
                <input
                  type="email"
                  name="email"
                  value={email}
                  required
                  onChange={(e) => setEmail(e.target.value)}
                  id="email"
                  className=" text-[12px] leading-6 appearance-none w-full h-full bg-transparent outline-none border-none"
                  placeholder="Enter your email"
                />
                <button
                  type="Submit"
                  className=" rounded-sm bg-main text-white px-6 h-9 text-[11px] font-bold  cursor-pointer uppercase   "
                >
                  {isSending ? (
                    <svg
                      aria-hidden="true"
                      role="status"
                      className="inline w-4 h-4 mr-3 text-white animate-spin"
                      viewBox="0 0 100 101"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                        fill="#E5E7EB"
                      />
                      <path
                        d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                        fill="currentColor"
                      />
                    </svg>
                  ) : (
                    "Subscribe"
                  )}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div className=" py-6 flex items-center justify-center border-t border-t-[#303639] ">
        <p className=" text-[#ADB7BC] text-14 font-normal leading-5 ">
          <span className=" uppercase hover:text-teal-400 cursor-grab   ">
            CREATI DIGITAL{" "}
          </span>{" "}
          © {new Date().getFullYear()}. All rights reserved
        </p>
      </div>
    </footer>
  );
};

export default Footer;
